import { API } from "aws-amplify";

export const lookupOrgId = async ({ orgId, country }) => {
  let result;

  try {
    const myInit = {
      queryStringParameters: {
        orgId,
        op: "lookup",
        country,
      },
    };

    result = await API.get("organisation", "/lookupOp", myInit);
  } catch (e) {
    console.error(e);
  }

  console.log(result);
  return result;
};

export const verifyLookupOrgId = async ({ orgId, checkoutId, country }) => {
  let result;

  try {
    const myInit = {
      queryStringParameters: {
        orgId,
        checkoutId,
        op: "verifyLookup",
        country,
      },
    };

    result = await API.get("organisation", "/lookupOp", myInit);
  } catch (e) {
    console.error(e);
  }

  return result;
};
