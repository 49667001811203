import { useEffect, useState } from "react";

import { OverlaySpinner } from "components";
import {
  HiCheckCircle,
  HiChevronRight,
  HiOutlineOfficeBuilding,
  HiUser,
} from "react-icons/hi";
import { ALLOWED_COUNTRIES } from "variables";

export function PartnerRegisterConfirm({
  isLoading,
  partner,
  invites,
  err,
  submitRef,
  confirmRegister,
}) {
  const [govIdName, setGovIdName] = useState();
  const [selectPartner, setSelectPartner] = useState();
  const [selectInvites, setSelectInvites] = useState();

  useEffect(() => {
    setGovIdName(
      Object.entries(ALLOWED_COUNTRIES).find(
        ([_, country]) => country.countryCode === partner?.country
      )?.[1]?.govIdName
    );
  }, [partner]);

  const confirm = () => {
    confirmRegister({
      partner: selectPartner?.selected ? selectPartner : null,
      invites: selectInvites?.filter((invite) => invite.selected) || [],
    });
  };

  const handleSelectInvite = (index) => {
    let newInvites = selectInvites.map((invite, i) => ({
      ...invite,
      selected: i === index ? !invite.selected : false,
    }));
    setSelectInvites(newInvites);
    setSelectPartner({
      ...selectPartner,
      selected: false,
    });
  };

  const handleSelectPartner = () => {
    if (!selectPartner.selected) {
      setSelectInvites(
        selectInvites.map((invite) => ({
          ...invite,
          selected: false,
        }))
      );
    }

    setSelectPartner({
      ...selectPartner,
      selected: !selectPartner.selected,
    });
  };

  useEffect(() => {
    setSelectPartner({
      ...partner,
      selected: false,
    });
  }, [partner]);

  useEffect(() => {
    setSelectInvites(
      (invites || []).map((invite) => ({
        ...invite,
        selected: false,
      }))
    );
  }, [invites]);

  return (
    <>
      <div className="input-form flex w-full flex-col gap-4 tablet:mt-10">
        <div className="subtitle body-sm leading-[21px] text-gray-600">
          Select to continue
        </div>

        <div className="flex flex-col gap-2">
          {!!partner?.orgId && (
            <>
              <OrganisationItemDetails
                orgId={partner?.orgId}
                govIdName={govIdName}
                name={partner?.entityName}
                location={partner?.entityLocation}
                email={partner?.email}
                isSelected={selectPartner?.selected}
                handleSelect={handleSelectPartner}
              />
            </>
          )}

          {selectInvites?.length > 0 && (
            <>
              {selectInvites.map((invite, index) => (
                <>
                  <OrganisationItemDetails
                    orgId={invite?.orgId}
                    govIdName={govIdName}
                    name={invite?.partner?.name}
                    location={invite?.location}
                    email={invite.email}
                    isSelected={invite.selected}
                    isInvite={true}
                    handleSelect={() => handleSelectInvite(index)}
                  />
                </>
              ))}
            </>
          )}
        </div>

        {!!err?.msg && (
          <div className="body-sm font-medium text-red-600">{err.msg}</div>
        )}

        <button
          className="btn-primary px-4 py-2 text-sm font-medium tablet:w-fit tablet:self-end tablet:px-12"
          disabled={isLoading}
          ref={submitRef}
          type="button"
          onClick={confirm}
        >
          {isLoading && <OverlaySpinner />}
          Continue
        </button>
      </div>
    </>
  );
}

function OrganisationItemDetails({
  govIdName,
  orgId,
  name,
  email,
  location,
  isSelected,
  isInvite,
  handleSelect,
}) {
  return (
    <div
      className={`lookup-details rounded border-[1px] p-[20px] ${isSelected ? "border-green-600 bg-green-50" : "bg-gray-50"}`}
      onClick={handleSelect}
    >
      <div className="flex flex-row justify-between">
        <div className="mb-[16px] flex flex-row items-center justify-start gap-[16px]">
          <HiOutlineOfficeBuilding className="font-bold text-gray-500" />{" "}
          <span className="text-sm text-gray-600">Organisation details</span>
        </div>

        {isSelected ? (
          <HiCheckCircle className="h-5 w-5 text-green-500" />
        ) : (
          <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
        )}
      </div>

      <div className="org-name mb-[12px] flex flex-row items-center justify-start gap-[8px]">
        <span className="text-xl font-bold capitalize text-gray-900">
          {name}
        </span>
        {/* <HiCheckCircle className="h-5 w-5 text-green-500" /> */}
      </div>

      <div className="org-id">
        <span className="font-bold">{govIdName}</span> {orgId}
      </div>

      <div className="org-location">
        <span className="font-bold">Location</span> {location}
      </div>

      <div className="mt-6 body-sm flex flex-col gap-2 text-gray-400">
        <div className="flex flex-row gap-2">
          <HiChevronRight className="h-5 w-5" />
          <div className="text-link">
            {isInvite ? <>Setup new admin user</> : <>Setup new organisation</>}
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <HiUser className="h-5 w-5" />
          <div className="">{email}</div>
        </div>
      </div>
    </div>
  );
}
