import { lookupOrgId, verifyLookupOrgId } from "api";
import { sanitizeInputWithSpace } from "services/utils";

const lookup = async ({ orgId, checkoutId, country }) => {
  const sanitizedOrgId = sanitizeInputWithSpace(orgId);

  let result;

  if (!!checkoutId) {
    result = await verifyLookupOrgId({
      orgId: sanitizedOrgId,
      checkoutId,
      country,
    });
  } else {
    result = await lookupOrgId({ orgId: sanitizedOrgId, country });
  }

  console.log("result", result);

  return result;
};

export const OrgIdService = {
  lookup,
};
