import "../../pages/partners-program/partnerStyles.css";

import { useState } from "react";

import { getOrganisationPublic } from "api";
import {
  CountryInput,
  FlowbiteTextInput,
  HoneypotForm,
  SubmitButton,
} from "components";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { HiOfficeBuilding, HiRefresh } from "react-icons/hi";
import {
  OrgIdService,
  validateEmail,
  validateEmpty,
  validateOrgId,
} from "services";
import { ALLOWED_COUNTRIES } from "variables";

export function GetStartedCard({ handleGetStarted = (partnerState) => {} }) {
  const [country, setCountry] = useState("Australia");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [err, setErr] = useState("");
  const [partnerState, setPartnerState] = useState({});

  const handleInput = (event) => {
    setOrgId(event.target.value);
    setErr("");
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.clipboardData).getData("text");
    const cleanedPaste = paste.replace(/\s+/g, "");

    const input = event.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const textBefore = orgId.substring(0, start);
    const textAfter = orgId.substring(end, orgId.length);

    const newText = textBefore + cleanedPaste + textAfter;
    setOrgId(newText);
    setErr("");

    // Set the cursor position after the pasted text
    setTimeout(() => {
      input.selectionStart = input.selectionEnd = start + cleanedPaste.length;
    }, 0);
  };

  const handleReset = () => {
    setOrgId("");
    setErr("");
    setIsSuccess(false);
    setPartnerState({});
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();

    // Trim values in state
    let submitOrgId = orgId?.trim()?.replace(/\s/g, "");

    // Check for invalid fields
    if (!validateEmpty(submitOrgId) || !validateOrgId(submitOrgId)) {
      setErr(
        `You have entered an invalid ${
          ALLOWED_COUNTRIES[country]?.govIdName
        }! Please check again.`
      );
      setOrgId(submitOrgId);
      return;
    }

    // Retrieve information now with valid org id
    setIsLoading(true);
    let verify = {
      ...(await getOrganisationPublic(
        orgId,
        ALLOWED_COUNTRIES[country]?.countryCode
      )),
      ...(await OrgIdService.lookup({
        orgId,
        country: ALLOWED_COUNTRIES[country]?.countryCode,
      })),
    };

    // Check for errors
    if (verify?.error) {
      setErr(
        `No matching ${ALLOWED_COUNTRIES[country]?.govIdName} could be found! Please try again.`
      );
      setOrgId(submitOrgId);
      setIsLoading(false);
      return;
    } else if (!!verify?.pendingPartner?.orgId) {
      setErr(
        `A partner is already signing up with this ${ALLOWED_COUNTRIES[country]?.govIdName}! Please check the email address associated with ${verify?.pendingPartner?.email}.`
      );
      setOrgId(submitOrgId);
      setIsLoading(false);
      return;
    }

    // Update partner state with retrieved info
    if (!verify?.partner?.orgId) {
      setPartnerState({
        orgId: { value: verify?.result?.orgId, error: false },
        govId: { value: verify?.result?.govId, error: false },
        entityName: { value: verify?.result?.name, error: false },
        entityType: { value: verify?.result?.type, error: false },
        adminEmail: {
          value: validateEmail(verify?.result?.customer?.adminEmail)
            ? verify?.result?.customer?.adminEmail
            : "",
          error: false,
        },
        adminFirstName: {
          value: verify?.result?.customer?.adminFirstName,
          error: false,
        },
        adminLastName: {
          value: verify?.result?.customer?.adminLastName,
          error: false,
        },
        adminRole: { value: verify?.result?.customer?.adminRole, error: false },
        adminPhone: {
          value: verify?.result?.customer?.adminPhone,
          error: false,
        },
        adminAuthMobile: {
          value: verify?.result?.customer?.adminAuthMobile,
          error: false,
        },
        lockAdminEmail: false, // validateEmail(verify?.result?.customer?.adminEmail),
      });
      setIsSuccess(true);
    } else if (!!verify?.partner) {
      setErr(
        `${ALLOWED_COUNTRIES[country]?.govIdName} already claimed! Please try again.`
      );
    } else {
      setErr(
        `${ALLOWED_COUNTRIES[country]?.govIdName} not accepted! Please try again.`
      );
    }
    setOrgId(submitOrgId);
    setIsLoading(false);
  };

  return (
    <div className="relative flex xl:mh-80 w-full flex-shrink-0 flex-col gap-5 md:gap-8 xl:justify-between rounded-xl bg-white p-5 md:p-8 shadow-sm">
      <div className="flex flex-row items-center gap-2 ">
        <BsFillArrowRightCircleFill className="md:size-6 xl:size-10 text-lime-500" />
        <h3 className="h3 text-black">Get Started</h3>
      </div>

      <div className="top-26 absolute text-sm font-medium text-red-600">
        {err}
      </div>

      <HoneypotForm
        className="flex flex-col gap-4"
        onSubmit={
          !isSuccess
            ? handleSubmit
            : (event) =>
                handleGetStarted(event, {
                  ...partnerState,
                  orgCountry: {
                    value: ALLOWED_COUNTRIES[country]?.countryCode,
                    error: false,
                  },
                  orgCountryName: {
                    value: country,
                    error: false,
                  },
                })
        }
      >
        {!isSuccess ? (
          <div className="flex flex-col gap-4 self-stretch">
            <p className="body-sm xl:body-md text-gray !font-normal">
              To start, please enter your{" "}
              {ALLOWED_COUNTRIES[country]?.govIdName}:
            </p>

            <CountryInput
              disabled={isLoading}
              id="country"
              name="country"
              required
              shadow
              value={country}
              onChange={(event) => setCountry(event?.target?.value)}
              color={"primary"}
            />

            <FlowbiteTextInput
              autoComplete="off"
              color={!!err ? "failure" : "primary"}
              disabled={isLoading}
              className="w-full"
              name="orgId"
              placeholder={`Enter your ${ALLOWED_COUNTRIES[country]?.govIdName}`}
              icon={HiOfficeBuilding}
              required
              type="tel"
              value={orgId}
              onInput={handleInput}
              onPaste={handlePaste}
              theme={{
                field: {
                  input: {
                    sizes: {
                      md: "px-4 py-[13px] text-xs xl:text-sm border border-gray-300 disabled:border-gray-300 focus:border-gray-900 focus:ring-gray-900 bg-gray-50",
                    },
                  },
                },
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4 self-stretch">
            <p className="body-md text-gray !font-normal">
              Is this your organisation?
            </p>

            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <span className="text-xl font-bold capitalize text-gray-900">
                  {partnerState?.entityName?.value}
                </span>
                <div>
                  <span className="font-bold">
                    {ALLOWED_COUNTRIES[country]?.govIdName}
                  </span>{" "}
                  <span>{partnerState?.orgId?.value}</span>
                </div>
              </div>

              <div className="text-gray flex gap-1 text-sm">
                If not,
                <button
                  className="flex items-center gap-1 font-medium text-blue-600 outline-none focus:underline enabled:hover:opacity-70"
                  onClick={handleReset}
                >
                  Search Again <HiRefresh />
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex w-full flex-col items-end">
          <SubmitButton
            className="w-fit px-6 py-2"
            isLoading={isLoading}
            spinnerSize="sm"
            type="submit"
            variant="partner"
          >
            Continue
          </SubmitButton>
        </div>
      </HoneypotForm>
    </div>
  );
}

{
  /* <form
  className={`input-form flex flex-1 flex-col justify-end gap-4 ${!err && "tablet:pt-[69px]"}`}
  onSubmit={handleSubmit}
>
  {!!err?.msg && (
    <Alert
      color="failure"
      icon={BiSolidErrorAlt}
      onDismiss={() => setErr(null)}
    >
      {err.msg}
    </Alert>
  )}

  <div className="subtitle body-sm leading-[21px] text-gray-600">
    To start, please enter your {ALLOWED_COUNTRIES[country]?.govIdName}:
  </div>

  <FlowbiteTextInput
    color={partnerState?.orgId?.error ? "failure" : "gray"}
    disabled={isLoading}
    name="orgId"
    icon={HiOfficeBuilding}
      placeholder={`Enter your ${ALLOWED_COUNTRIES[country]?.govIdName}`}
    required
    type="text"
    value={partnerState?.orgId?.value}
    onKeyDown={(event) =>
      handleEnterKeyFormSubmission(event, () => handleSubmit(event))
    }
    onInput={handleInput}
  />

  <div className="flex gap-1 body-sm text-gray-900">
    <div>Cannot find your organisation or it's been claimed?</div>
    <button
      onClick={openZEWidget}
      className="font-medium text-blue-600 outline-none hover:opacity-70 focus:underline"
      type="button"
    >
      Get Help
    </button>
  </div>

  <SubmitButton
    className="self-end"
    id="submitOrgId"
    variant="small"
    type="submit"
    isLoading={isLoading}
  >
    Continue
  </SubmitButton>
</form>; */
}
