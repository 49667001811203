import { useEffect, useState } from "react";

import { PhoneNumberUtil } from "google-libphonenumber";

export const delay = (milliseconds) =>
  new Promise((resolve) => setTimeout(() => resolve(), milliseconds));

// Compare two cert requirement base IDs.
export const compareBaseIds = (baseIdA, baseIdB) => {
  const partsA = baseIdA.split(".").map(Number);
  const partsB = baseIdB.split(".").map(Number);

  for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
    if (partsA[i] !== partsB[i]) {
      return partsA[i] - partsB[i];
    }
  }

  return partsA.length - partsB.length;
};

/**
 * Splits an array into chunks of a specified size.
 *
 * @param {Array} array - The array to be chunked.
 * @param {number} size - The size of each chunk.
 * @returns {Array<Array>} - A new array containing the chunks.
 * @throws {Error} - Throws an error if the input array is not an array or the size is less than or equal to 0.
 *
 * @example
 *
 * const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
 * const size = 3;
 * const result = chunk(arr, size);
 * console.log(result);
 * // Output: [[1, 2, 3], [4, 5, 6], [7, 8, 9]]
 */
export const chunk = (array, size) => {
  if (!Array.isArray(array) || size <= 0) {
    throw new Error(
      "Invalid input: 'array' should be an array and 'size' should be greater than 0."
    );
  }

  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

/**
 * Splits an array into chunks, distributing the items as evenly as possible,
 * while keeping each chunk size within the specified maximum.
 *
 * @param {Array} array - The array to be chunked.
 * @param {number} maxChunkSize - The maximum size of each chunk.
 * @returns {Array<Array>} - An array of chunks, where each chunk is an array of elements.
 */
export const chunkEvenly = (array, maxChunkSize) => {
  // Validate input
  if (
    !Array.isArray(array) ||
    typeof maxChunkSize !== "number" ||
    maxChunkSize <= 0
  ) {
    throw new Error("Invalid input");
  }

  const chunks = [];
  const totalItems = array.length;

  // Calculate the number of chunks needed
  const numChunks = Math.ceil(totalItems / maxChunkSize);

  // Calculate the base chunk size
  const baseChunkSize = Math.floor(totalItems / numChunks);
  // Calculate the number of chunks that need to be one item larger
  const extraChunkCount = totalItems % numChunks;

  let startIndex = 0;

  for (let i = 0; i < numChunks; i++) {
    // Determine chunk size for this chunk
    const currentChunkSize = baseChunkSize + (i < extraChunkCount ? 1 : 0);

    // Slice the chunk from the array
    const chunk = array.slice(startIndex, startIndex + currentChunkSize);
    chunks.push(chunk);

    // Move to the next start index
    startIndex += currentChunkSize;
  }

  return chunks;
};

export const clearUrlParamsState = () => {
  window.history.replaceState(
    null,
    "",
    `${window.location.origin}${window.location.pathname}`
  );
};

export const sanitizeInputWithSpace = (s) => {
  return s?.replace(/\s/g, "") || "";
};

export const openZEWidget = () => {
  window.zE?.activate();
};

export const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { windowWidth, windowHeight };
};

export const handleKeyDownInput = (event) => {
  if (
    (event?.key === " " || event?.key === "Enter") &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    event?.preventDefault();
    event?.target?.click();
  }
};

export const handleKeyDownSubmission = (event, target) => {
  if (
    event?.key === "Enter" &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    target?.current?.click();
  }
};

export const handleEnterKeyFormSubmission = (event, callback = () => {}) => {
  if (
    event?.key === "Enter" &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    callback();
  }
};

// Sleep function using Promise and setTimeout
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Function to format seconds into MM:SS format.
 *
 * @param {number} seconds - The number of seconds to format.
 * @returns {string} - The formatted time string in MM:SS format.
 */
export const formatSecondsToMMSS = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const calculateEpochTimeInSecond = (time) => {
  const currentTime = Math.floor(Date.now() / 1000);
  const timeEpoch = Math.floor(new Date(time).getTime() / 1000);
  return timeEpoch - currentTime;
};

/**
 * Function to get the country code for a given international phone number.
 *
 * @param {string} phoneNumber - The international phone number string.
 * @returns {string|null} - The country code in two-letter format if valid, null otherwise.
 */
export const getCountryCodeFromPhoneNumber = (phoneNumber) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const code = phoneUtil.getRegionCodeForNumber(
      phoneUtil.parse(
        phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`
      )
    );
    return code.toLowerCase();
  } catch (error) {
    // console.error("Invalid phone number: ", error);
    return undefined;
  }
};
