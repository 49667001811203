import { API } from "aws-amplify";

export const verifyPartnerAccount = async ({
  username,
  email,
  orgId,
  isActive,
  isSetup,
  phone,
  phoneVerifyCode,
  referralCode,
}) => {
  let result;
  try {
    const myInit = {
      queryStringParameters: {
        username,
        email,
        orgId,
        isActive,
        isSetup,
        phone,
        phoneVerifyCode,
        referralCode,
      },
    };
    result = await API.get("partner", "/verify", myInit);
  } catch (e) {
    console.error(e);
  }
  return result;
};
