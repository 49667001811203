export const ROUTES = {
  SELF: {
    // for Route
    ROOT: "",
    LOGIN: "login",
    PARTNER_LOGIN: "login",
    CLAIM: "claim",
    RESET: "reset",
    TERMS: "terms",
    PRIVACY_POLICY: "privacy-policy",
    COOKIE_POLICY: "cookie-policy",
    CPS: "SMB1001CPS.pdf",
    T2_INVITE: "invite/:token",
    PARTNER_OFFER: "offer/:token",
    PARTNER_INVITE: "register",
    PARTNER_FORM: "signup",
    PARTNER_FORM_OLD: "signup",
    PARTNER_FORM_REF: "ref/:code",
    MAINTENANCE: "maintenance",
    QR: "QR/:code",
    PARTNERS_MSP: "msp/:referralCode?",
    TEST: "test",
  },
  PAGE_NOT_FOUND: "*",
  404: "/404",
};

Object.keys(ROUTES.SELF).forEach((k) => {
  // for navigate, Link
  ROUTES[k] = `/${ROUTES.SELF[k]}`;
});

export const DEFAULT_LANGUAGE = "en-au";
export const LANGUAGES = ["en-au", "fr-fr"];

export const LEVELS = {
  1: "Bronze",
  2: "Silver",
  3: "Gold",
  4: "Platinum",
  5: "Diamond",
};

export const LEVEL_NAMES = {
  Bronze: 0,
  Silver: 1,
  Gold: 2,
  Platinum: 3,
  Diamond: 4,
};

export const ALLOWED_INDUSTRIES = [
  "Financial Services",
  "Government",
  "Healthcare",
  "Hospitality",
  "Information Technology",
  "Legal",
  "Manufacturing",
  "Not-for-Profit",
  "Professional Services",
  "Retail",
  "Transportation",
  "Other",
];

export const LVL_ANIMS_SAFARI = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-animated-sf.mov",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-animated-sf.mov",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-animated-sf.mov",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-animated-sf.mov",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-animated-sf.mov",
  All: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/spin-all-levels-sf.mov",
};

export const LVL_ANIMS_CHROME = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-animated-cr.webm",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-animated-cr.webm",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-animated-cr.webm",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-animated-cr.webm",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-animated-cr.webm",
  All: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/spin-all-levels-cr.webm",
};

export const LEVEL_BACKGROUND_COLOURS = {
  1: "bg-bronze",
  2: "bg-silver",
  3: "bg-gold",
  4: "bg-platinum",
  5: "bg-diamond",
};

export const LEVEL_BADGES = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-default.png",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-default.png",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-default.png",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-default.png",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-default.png",
};

export const LEVEL_DETAILS = {
  1: [
    "SMB1001 certification",
    "Implement 6 requirements",
    // "Perfect for micro and small businesses",
    "Self-Attested",
  ],
  2: [
    "SMB1001 certification",
    // "Perfect for micro and small businesses",
    "Implement 14 requirements",
    "Self-Attested",
  ],
  3: [
    "SMB1001 certification",
    // "Perfect for small and medium businesses",
    "Implement 22 requirements",
    "Self-Attested",
  ],
  4: [
    "SMB1001 certification",
    // "Perfect for small, medium and large businesses",
    "Implement 28 requirements",
    "Self-Attested + External Audit",
  ],
  5: [
    "SMB1001 certification",
    // "Perfect for medium and large businesses",
    "Implement 35 requirements",
    "Self-Attested + External Audit",
  ],
};

export const LEVEL_ICONS = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-icon.png",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-icon.png",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-icon.png",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-icon.png",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-icon.png",
};

export const LEVEL_PRICES = {
  1: 95,
  2: 195,
  3: 395,
  4: "3,595",
  5: "5,995",
};

export const LEVEL_TEXT_COLOURS = {
  1: "text-bronze",
  2: "text-silver",
  3: "text-gold",
  4: "text-platinum",
  5: "text-diamond",
};

export const STANDARD_LINK = "https://dsi.org/smb1001";

export const CAP_LINK =
  "https://cscau-public.s3.ap-southeast-2.amazonaws.com/Cyber+Assurance+Program+(CAP)+-+MSPs+V1.1.pdf";
export const CAP_DOWNLOAD = "Cyber Assurance Program.pdf";

export const ALLOWED_COUNTRIES = {
  // Andorra: "AD",
  // "Antigua and Barbuda": "AG",
  // Argentina: "AR",
  // Armenia: "AM",
  Australia: {
    countryCode: "au",
    dialCode: "+61",
    govIdName: "ABN",
    isActive: true,
  },
  "New Zealand": {
    countryCode: "nz",
    dialCode: "+64",
    govIdName: "NZBN",
    isActive: true,
  },
  // Austria: "AT",
  // Azerbaijan: "AZ",
  // Bahamas: "BS",
  // Bahrain: "BH",
  // Bangladesh: "BD",
  // Barbados: "BB",
  // Belarus: "BY",
  // Belgium: "BE",
  // Belize: "BZ",
  // Benin: "BJ",
  // Bhutan: "BT",
  // Bolivia: "BO",
  // "Bosnia and Herzegovina": "BA",
  // Botswana: "BW",
  // Brazil: "BR",
  // Brunei: "BN",
  // Bulgaria: "BG",
  // "Burkina Faso": "BF",
  // Burundi: "BI",
  // "Cabo Verde": "CV",
  // Cambodia: "KH",
  // Cameroon: "CM",
  // Canada: "CA",
  // "Central African Republic": "CF",
  // Chad: "TD",
  // Chile: "CL",
  // China: "CN",
  // Colombia: "CO",
  // Comoros: "KM",
  // Congo: "CG",
  // "Congo, Democratic Republic of the": "CD",
  // "Costa Rica": "CR",
  // Croatia: "HR",
  // Cuba: "CU",
  // Cyprus: "CY",
  // "Czech Republic": "CZ",
  // Denmark: "DK",
  // Djibouti: "DJ",
  // Dominica: "DM",
  // "Dominican Republic": "DO",
  // Ecuador: "EC",
  // Egypt: "EG",
  // "El Salvador": "SV",
  // "Equatorial Guinea": "GQ",
  // Eritrea: "ER",
  // Estonia: "EE",
  // Eswatini: "SZ",
  // Ethiopia: "ET",
  // Fiji: "FJ",
  // Finland: "FI",
  // France: "FR",
  // Gabon: "GA",
  // Gambia: "GM",
  // Georgia: "GE",
  // Germany: "DE",
  // Ghana: "GH",
  // Greece: "GR",
  // Grenada: "GD",
  // Guatemala: "GT",
  // Guinea: "GN",
  // "Guinea-Bissau": "GW",
  // Guyana: "GY",
  // Haiti: "HT",
  // Honduras: "HN",
  // Hungary: "HU",
  // Iceland: "IS",
  // India: "IN",
  // Indonesia: "ID",
  // Iran: "IR",
  // Iraq: "IQ",
  // Ireland: "IE",
  // Israel: "IL",
  // Italy: "IT",
  // Jamaica: "JM",
  // Japan: "JP",
  // Jordan: "JO",
  // Kazakhstan: "KZ",
  // Kenya: "KE",
  // Kiribati: "KI",
  // "Korea, North": "KP",
  // "Korea, South": "KR",
  // Kuwait: "KW",
  // Kyrgyzstan: "KG",
  // Laos: "LA",
  // Latvia: "LV",
  // Lebanon: "LB",
  // Lesotho: "LS",
  // Liberia: "LR",
  // Libya: "LY",
  // Liechtenstein: "LI",
  // Lithuania: "LT",
  // Luxembourg: "LU",
  // Madagascar: "MG",
  // Malawi: "MW",
  // Malaysia: "MY",
  // Maldives: "MV",
  // Mali: "ML",
  // Malta: "MT",
  // "Marshall Islands": "MH",
  // Mauritania: "MR",
  // Mauritius: "MU",
  // Mexico: "MX",
  // Micronesia: "FM",
  // Moldova: "MD",
  // Monaco: "MC",
  // Mongolia: "MN",
  // Montenegro: "ME",
  // Morocco: "MA",
  // Mozambique: "MZ",
  // Myanmar: "MM",
  // Namibia: "NA",
  // Nauru: "NR",
  // Nepal: "NP",
  // Netherlands: "NL",
  // Nicaragua: "NI",
  // Niger: "NE",
  // Nigeria: "NG",
  // "North Macedonia": "MK",
  // Norway: "NO",
  // Oman: "OM",
  // Pakistan: "PK",
  // Palau: "PW",
  // Panama: "PA",
  // "Papua New Guinea": "PG",
  // Paraguay: "PY",
  // Peru: "PE",
  // Philippines: "PH",
  // Poland: "PL",
  // Portugal: "PT",
  // Qatar: "QA",
  // Romania: "RO",
  // Russia: "RU",
  // Rwanda: "RW",
  // "Saint Kitts and Nevis": "KN",
  // "Saint Lucia": "LC",
  // "Saint Vincent and the Grenadines": "VC",
  // Samoa: "WS",
  // "San Marino": "SM",
  // "Sao Tome and Principe": "ST",
  // "Saudi Arabia": "SA",
  // Senegal: "SN",
  // Serbia: "RS",
  // Seychelles: "SC",
  // "Sierra Leone": "SL",
  // Singapore: "SG",
  // Slovakia: "SK",
  // Slovenia: "SI",
  // "Solomon Islands": "SB",
  // Somalia: "SO",
  // "South Africa": "ZA",
  // "South Sudan": "SS",
  // Spain: "ES",
  // "Sri Lanka": "LK",
  // Sudan: "SD",
  // Suriname: "SR",
  // Sweden: "SE",
  // Switzerland: "CH",
  // Syria: "SY",
  // Taiwan: "TW",
  // Tajikistan: "TJ",
  // Tanzania: "TZ",
  // Thailand: "TH",
  // "Timor-Leste": "TL",
  // Togo: "TG",
  // Tonga: "TO",
  // "Trinidad and Tobago": "TT",
  // Tunisia: "TN",
  // Turkey: "TR",
  // Turkmenistan: "TM",
  // Tuvalu: "TV",
  // Uganda: "UG",
  // Ukraine: "UA",
  // "United Arab Emirates": "AE",
  // "United Kingdom": "GB",
  // "United States": "US",
  // Uruguay: "UY",
  // Uzbekistan: "UZ",
  // Vanuatu: "VU",
  // "Vatican City": "VA",
  // Venezuela: "VE",
  // Vietnam: "VN",
  // Yemen: "YE",
  // Zambia: "ZM",
  // Zimbabwe: "ZW",
};

export const ALLOWED_STATES = {
  au: {
    ACT: "Australia Capital Territory",
    NSW: "New South Wales",
    NT: "Northern Territory",
    QLD: "Queensland",
    SA: "South Australia",
    TAS: "Tasmania",
    VIC: "Victoria",
    WA: "Western Australia",
  },
  nz: {
    NTL: "Northland",
    AUK: "Auckland",
    WKO: "Waikato",
    BOP: "Bay of Plenty",
    GIS: "Gisborne",
    HKB: "Hawke's Bay",
    TKI: "Taranaki",
    MWT: "Manawatū-Whanganui",
    WGN: "Wellington",
    TAS: "Tasman",
    NSN: "Nelson",
    MBH: "Marlborough",
    WTC: "West Coast",
    CAN: "Canterbury",
    OTA: "Otago",
    STL: "Southland",
  },
};
