import { API } from "aws-amplify";

export const verifyPartnerInvite = async ({ code }) => {
  const myInit = {
    queryStringParameters: {
      code,
    },
  };
  const result = await API.get("partner", "/invite/verify", myInit);
  // console.log(result);
  return result;
};

export const acceptPartnerInvite = async ({ code, orgId, status }) => {
  const myInit = {
    body: {
      code,
      orgId,
      status
    },
  };
  const result = await API.post("partner", "/invite/accept", myInit);
  // console.log(result);
  return result;
};

export const acceptPartnerNewUserInvite = async ({ userInvites }) => {
  const myInit = {
    body: {
      userInvites,
    },
  };
  const result = await API.post("partner", "/user-invite/accept", myInit);
  // console.log(result);
  return result;
};
