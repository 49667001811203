import { useEffect, useRef, useState } from "react";

import { HiCheck } from "react-icons/hi";
import { VscTriangleDown } from "react-icons/vsc";
import Flag from "react-world-flags";
import { twMerge } from "tailwind-merge";
import { ALLOWED_COUNTRIES } from "variables";

export function InternationalPhoneNumberInput({
  color = "primary",
  disabled = false,
  initialCountry = "au",
  initialValue = "",
  name = "",
  required,
  placeholder = "",
  onInput = () => {},
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState(initialCountry || "au");
  const [value, setValue] = useState(() => {
    const dialCode =
      Object.values(ALLOWED_COUNTRIES).find(
        (c) => c.countryCode === initialCountry
      )?.dialCode || "";
    return initialValue.replace(
      new RegExp(`^\\+?(${dialCode.replace("+", "")})?`),
      ""
    );
  });

  const buttonRef = useRef(null);
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleInput = (event) => {
    setValue(event.target.value);
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const paste = event.clipboardData.getData("text").replace(/\s/g, "");
    if (/[0-9\s]/.test(paste)) {
      const input = event.target;
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;

      setValue((prev) => {
        const newValue =
          prev.substring(0, startPos) + paste + prev.substring(endPos);
        setTimeout(() => {
          input.setSelectionRange(
            startPos + paste.length,
            startPos + paste.length
          );
        }, 0);
        return newValue;
      });
    }
  };

  const handleSelect = (country) => {
    setCountry(country);
    setIsOpen(false);
  };

  const handleBlur = (event) => {
    if (
      !buttonRef.current?.contains(event.relatedTarget) &&
      !dropdownRef.current?.contains(event.relatedTarget)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    onInput({
      target: {
        country,
        name,
        value:
          Object.values(ALLOWED_COUNTRIES)
            ?.find((c) => c.countryCode === country)
            ?.dialCode.replace(/^\+/, "") + value?.replace(/^0+/, ""),
      },
    });
  }, [value, country]);

  return (
    <div
      className="relative flex h-fit w-full items-center"
      ref={containerRef}
      onBlur={handleBlur}
      onKeyDown={(event) => {
        if (isOpen && event.key === "ArrowDown") {
          event.preventDefault();
          dropdownRef.current?.children?.[0]?.focus();
        }
      }}
    >
      <button
        className={twMerge(
          "flex flex-shrink-0 items-center gap-2 self-stretch rounded-l-lg border border-r-0 p-2 outline-none hover:bg-gray-200 focus:border-r focus:ring-primary focus:border-primary focus:ring-1 focus:ring-inset",
          COLORS[color],
          "bg-gray-100 disabled:bg-gray-300"
        )}
        disabled={disabled}
        ref={buttonRef}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Flag code={country} className="w-5" fallback={<span>🌏</span>} />
        <span>
          {
            Object.values(ALLOWED_COUNTRIES)?.find(
              (c) => c.countryCode === country
            )?.dialCode
          }
        </span>
        <VscTriangleDown
          className={twMerge(
            "transition-base size-2.5 fill-gray-900",
            isOpen ? "rotate-180" : ""
          )}
        />
      </button>

      <div
        className={`absolute top-full z-10 my-2 grid w-64 overflow-hidden rounded-lg transition-all duration-500 ease-in-out ${isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"}`}
      >
        <div
          className={`border-gray-200 overflow-hidden rounded-lg bg-white shadow-md transition-[border-width] duration-500 ease-in-out ${isOpen ? "border" : "border-0"}`}
          ref={dropdownRef}
          tabIndex={isOpen ? 0 : -1}
          onFocus={(event) => {
            if (dropdownRef.current?.contains(event.relatedTarget)) {
              containerRef.current?.children?.[0]?.focus();
            } else {
              dropdownRef.current?.children?.[0]?.focus();
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              event.preventDefault();
              setIsOpen(false);
            }
          }}
        >
          {Object.entries(ALLOWED_COUNTRIES).map(
            ([countryName, { countryCode, dialCode }], index) => (
              <button
                key={index}
                className="flex w-full items-center justify-between p-2 hover:bg-gray-100 focus:bg-blue-200"
                type="button"
                onClick={() => handleSelect(countryCode)}
                onKeyDown={(event) => {
                  if (event.key === "ArrowDown") {
                    event.preventDefault();
                    if (index < dropdownRef.current?.children?.length - 1) {
                      dropdownRef.current?.children?.[index + 1]?.focus();
                    }
                  }
                  if (event.key === "ArrowUp") {
                    event.preventDefault();
                    if (index !== 0) {
                      dropdownRef.current?.children?.[index - 1]?.focus();
                    }
                  }
                }}
                onFocus={(event) => event.stopPropagation()}
                tabIndex={isOpen ? 0 : -1}
              >
                <div className="flex items-center gap-2">
                  <Flag
                    code={countryCode}
                    className="h-3.5 w-5"
                    fallback={<span>🌏</span>}
                  />
                  {countryName}
                  <span className="text-gray">{dialCode}</span>
                </div>

                {countryCode === country && (
                  <HiCheck className="size-4 text-brand" />
                )}
              </button>
            )
          )}
        </div>
      </div>

      <input
        className={twMerge(
          "block w-full rounded-r-lg border px-4 py-[13px] text-sm outline-none focus:ring-inset",
          COLORS[color]
        )}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        ref={inputRef}
        required={required}
        type="tel"
        value={value}
        onInput={handleInput}
        onPaste={handlePaste}
        {...props}
      />
    </div>
  );
}

const COLORS = {
  primary:
    "border-gray-300 surface-primary disabled:surface-border-primary disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-icons focus:border-primary focus:ring-primary disabled:placeholder-transparent",
  failure:
    "border-red-500 bg-red-50 text-red-900 placeholder-red-400 focus:border-red-500 focus:ring-red-500 disabled:border-red-200 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
};
