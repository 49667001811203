import { useEffect, useState } from "react";

import {
  BackButton,
  FlowbiteTextInput,
  InternationalPhoneNumberInput,
  Select,
  SubmitButton,
} from "components";
import { Alert } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";
import {
  getCountryCodeFromPhoneNumber,
  handleEnterKeyFormSubmission,
  validateEmpty,
  validateInternationalPhoneNumber,
  validateURL,
} from "services";
import { ALLOWED_INDUSTRIES } from "variables";

const EMPLOYEE_OPTIONS = [
  "Select number of employees",
  "1-20",
  "20-200",
  "200-500",
  "500+",
];

export function PartnerFormInfoStep({
  partnerState,
  setPartnerState,
  handleGovIdInput = () => {},
  handleInput = () => {},
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const [govId, setGovId] = useState(
    !!partnerState?.govId?.value ? JSON.parse(partnerState.govId.value) : {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState({});
  const [numEmployeeValue, setNumEmployeeValue] = useState(
    !!partnerState?.numEmployee?.value
      ? EMPLOYEE_OPTIONS.indexOf(partnerState.numEmployee.value)
      : 0
  );
  const [industryValue, setIndustryValue] = useState(
    !!partnerState?.industrySector?.value
      ? ALLOWED_INDUSTRIES.indexOf(partnerState.industrySector.value)
      : 0
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Trim values in state
    let submitState = { ...partnerState };
    for (const key in submitState) {
      if (submitState?.hasOwnProperty(key)) {
        submitState[key] = {
          ...submitState?.[key],
          value: submitState?.[key]?.value?.trim(),
        };
      }
    }

    // Check for invalid fields
    let isInvalidField = false;
    let isNotSelected = false;

    if (
      validateEmpty(submitState?.telephone?.value) &&
      !validateInternationalPhoneNumber(
        submitState?.telephone?.value,
        submitState?.telephone?.country
      )
    ) {
      submitState.telephone.error = true;
      isInvalidField = true;
    }
    if (!validateEmpty(submitState?.industrySector?.value)) {
      submitState.industrySector.error = true;
      isInvalidField = true;
    }
    if (
      !validateEmpty(submitState?.website?.value) ||
      !validateURL(submitState?.website?.value)
    ) {
      submitState.website.error = true;
      isInvalidField = true;
    }
    if (partnerState?.numEmployee?.value === EMPLOYEE_OPTIONS[0]) {
      submitState.numEmployee.error = true;
      isNotSelected = true;
    }

    if (isInvalidField) {
      setErr({
        type: "invalid",
        msg: "You have entered an invalid field. Please try again.",
      });
      setPartnerState(submitState);
      return;
    } else if (isNotSelected) {
      setErr({
        type: "non-selection",
        msg: "You have not selected an option.",
      });
      setPartnerState(submitState);
      return;
    }

    // Goto next step now
    setIsLoading(false);
    handleNextStep();
  };

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      industrySector: {
        ...prevState.industrySector,
        value: ALLOWED_INDUSTRIES[industryValue],
        error: false,
      },
    }));
  }, [industryValue]);

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      numEmployee: {
        ...prevState.numEmployee,
        value: EMPLOYEE_OPTIONS[numEmployeeValue],
        error: false,
      },
    }));
  }, [numEmployeeValue]);

  useEffect(() => {
    if (Object?.values(partnerState)?.every((obj) => !obj.error)) {
      setErr(null);
    }
    setGovId(
      !!partnerState?.govId?.value ? JSON.parse(partnerState.govId.value) : {}
    );
  }, [partnerState]);

  return (
    <>
      {!!err?.msg && (
        <Alert
          color="red"
          icon={BiSolidErrorAlt}
          onDismiss={() => setErr(null)}
        >
          {err?.msg}
        </Alert>
      )}

      <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          {govId?.identifiers?.map((id, index) => (
            <>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-medium text-gray-600">
                  {id?.type === "ACN" ? "ACN (if applicable)" : id?.type}
                </span>

                <FlowbiteTextInput
                  autocomplete="off"
                  color={"gray"}
                  disabled={isLoading || index === 0}
                  name={id?.type}
                  placeholder={`Enter your ${id?.type}`}
                  type="tel"
                  value={id?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleGovIdInput}
                />
              </div>
            </>
          ))}

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Organisation Phone Number (if applicable)
            </span>

            <InternationalPhoneNumberInput
              autocomplete="off"
              color={partnerState?.telephone?.error ? "failure" : "primary"}
              disabled={isLoading}
              name="telephone"
              placeholder="Enter your organisation's contact number"
              type="tel"
              initialCountry={getCountryCodeFromPhoneNumber(
                partnerState?.telephone?.value
              )}
              initialValue={partnerState?.telephone?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Industry Sector
            </span>

            <Select
              className="text-sm"
              disabled={isLoading}
              error={partnerState?.industrySector?.error}
              options={ALLOWED_INDUSTRIES}
              value={industryValue}
              setValue={setIndustryValue}
              onChange={(event) => setIndustryValue(event.target.value)}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Website URL
            </span>

            <FlowbiteTextInput
              autocomplete="off"
              color={partnerState?.website?.error ? "failure" : "gray"}
              defaultValue={"https://"}
              disabled={isLoading}
              name="website"
              placeholder="Enter your website URL"
              required
              type="url"
              value={partnerState?.website?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Approximate No. of Employees
            </span>

            <Select
              className="text-sm"
              disabled={isLoading}
              error={partnerState.numEmployee.error}
              options={EMPLOYEE_OPTIONS}
              value={numEmployeeValue}
              setValue={setNumEmployeeValue}
              onChange={(event) => setNumEmployeeValue(event.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col items-center gap-4 tablet:flex-row tablet:justify-end">
          <BackButton
            variant="small"
            isLoading={isLoading}
            onClick={handlePrevStep}
          >
            Back
          </BackButton>

          <SubmitButton variant="small" type="submit" isLoading={isLoading}>
            Continue
          </SubmitButton>
        </div>
      </form>
    </>
  );
}
