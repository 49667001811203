import { createContext, useContext, useEffect, useState } from "react";

const PartnerOfferContext = createContext(null);

export function PartnerOfferProvider({ children }) {
  const [partnerOffer, setPartnerOffer] = useState({
    ABN: sessionStorage.getItem("PARTNER_OFFER_PARTNER_ORG_ID"),
    name: sessionStorage.getItem("PARTNER_OFFER_PARTNER_NAME"),
    discount: sessionStorage.getItem("PARTNER_OFFER_DISCOUNT"),
    id: sessionStorage.getItem("PARTNER_OFFER_CODE"),
    logo: sessionStorage.getItem("PARTNER_OFFER_LOGO"),
    favicon: sessionStorage.getItem("PARTNER_OFFER_FAVICON"),
  });

  useEffect(() => {
    if (!!partnerOffer.ABN) {
      sessionStorage.setItem("PARTNER_OFFER_PARTNER_ORG_ID", partnerOffer.ABN);
    }
    if (!!partnerOffer.name) {
      sessionStorage.setItem("PARTNER_OFFER_PARTNER_NAME", partnerOffer.name);
    }
    if (!!partnerOffer.discount) {
      sessionStorage.setItem("PARTNER_OFFER_DISCOUNT", partnerOffer.discount);
    }
    if (!!partnerOffer.id) {
      sessionStorage.setItem("PARTNER_OFFER_CODE", partnerOffer.id);
    }
    if (!!partnerOffer.logo) {
      sessionStorage.setItem("PARTNER_OFFER_LOGO", partnerOffer.logo);
    }
    if (!!partnerOffer.favicon) {
      sessionStorage.setItem("PARTNER_OFFER_FAVICON", partnerOffer.favicon);
    }
  }, [partnerOffer]);

  return (
    <PartnerOfferContext.Provider value={{ partnerOffer, setPartnerOffer }}>
      {children}
    </PartnerOfferContext.Provider>
  );
}

export function usePartnerOffer() {
  const context = useContext(PartnerOfferContext);
  if (!context) {
    throw new Error(
      "usePartnerOffer must be used within a PartnerOfferProvider",
    );
  }
  return context;
}
