import { useEffect, useState } from "react";

import { getOrganisationPublic } from "api";
import { CountryInput, FlowbiteTextInput, SubmitButton } from "components";
import { Alert } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";
import { HiOfficeBuilding } from "react-icons/hi";
import {
  OrgIdService,
  handleEnterKeyFormSubmission,
  openZEWidget,
  validateOrgId,
  validateEmail,
  validateEmpty,
} from "services";
import { ALLOWED_COUNTRIES } from "variables";

export function PartnerFormOrgIdStep({
  partnerState,
  setPartnerState = () => {},
  handleInput = () => {},
  handleUpdate = () => {},
}) {
  const [country, setCountry] = useState("Australia");
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    // Trim values in state
    let submitState = { ...partnerState };
    for (const key in submitState) {
      if (submitState?.hasOwnProperty(key)) {
        submitState[key] = {
          ...submitState?.[key],
          value: submitState?.[key]?.value?.trim(),
        };
      }
    }

    // Check for invalid fields
    let isInvalidField = false;
    if (
      !validateEmpty(submitState?.orgId?.value) ||
      !validateOrgId(submitState?.orgId?.value)
    ) {
      submitState.orgId.error = true;
      isInvalidField = true;
    }
    if (!!isInvalidField) {
      setErr({
        type: "orgId",
        msg: `You have entered an invalid ${ALLOWED_COUNTRIES[country]?.govIdName}. Please check again.`,
      });
      setPartnerState(submitState);
      return;
    }

    // Retrieve information now with valid org id
    setIsLoading(true);
    let verify = {
      ...(await getOrganisationPublic(submitState?.orgId?.value)),
      ...(await OrgIdService?.lookup({
        orgId: submitState?.orgId?.value,
        country: ALLOWED_COUNTRIES[country]?.countryCode,
      })),
    };

    // Check for errors
    if (verify?.error) {
      setErr({
        type: "orgId",
        msg: `No matching ${ALLOWED_COUNTRIES[country]?.govIdName} could be found. Please try again.`,
      });
      submitState.orgId.error = true;
      isInvalidField = true;
      setPartnerState(submitState);
      setIsLoading(false);
      return;
    } else if (!!verify?.partner?.orgId) {
      setErr({
        type: "orgId",
        msg: `A partner account for this ${ALLOWED_COUNTRIES[country]?.govIdName} has already been created. Please check the email address associated with this partner.`,
      });
      submitState.orgId.error = true;
      isInvalidField = true;
      setPartnerState(submitState);
      setIsLoading(false);
      return;
    } else if (!!verify?.pendingPartner?.orgId) {
      setErr({
        type: "orgId",
        msg: `A partner form for this ${ALLOWED_COUNTRIES[country]?.govIdName} has already been submitted. Please check the email address associated with ${verify?.pendingPartner?.email}.`,
      });
      submitState.orgId.error = true;
      isInvalidField = true;
      setPartnerState(submitState);
      setIsLoading(false);
      return;
    }

    // Update partner state with retrieved info
    verify = verify?.result;
    if (!verify?.partner?.orgId) {
      setPartnerState({
        ...submitState,
        orgId: { value: verify?.orgId, error: false },
        govId: { value: verify?.govId, error: false },
        orgCountry: {
          value: ALLOWED_COUNTRIES[country]?.countryCode,
          error: false,
        },
        orgCountryName: {
          value: country,
          error: false,
        },
        headOfficeCountry: {
          value: country,
          error: false,
        },
        postalCountry: {
          value: country,
          error: false,
        },
        entityName: { value: verify?.name, error: false },
        entityType: { value: verify?.type, error: false },
        adminEmail: {
          value: validateEmail(verify?.customer?.adminEmail)
            ? verify?.customer?.adminEmail
            : "",
          error: false,
        },
        adminFirstName: {
          value: verify?.customer?.adminFirstName,
          error: false,
        },
        adminLastName: { value: verify?.customer?.adminLastName, error: false },
        adminRole: { value: verify?.customer?.adminRole, error: false },
        adminPhone: { value: verify?.customer?.adminPhone, error: false },
        adminAuthMobile: {
          value: verify?.customer?.adminAuthMobile,
          error: false,
        },
        lockAdminEmail: false, // validateEmail(verify?.customer?.adminEmail),
      });
    } else if (!!verify?.partner) {
      setErr({
        type: "orgId",
        msg: `${ALLOWED_COUNTRIES[country]?.govIdName} already claimed. Please try again.`,
      });
      submitState.orgId.error = true;
      isInvalidField = true;
      setPartnerState(submitState);
      setIsLoading(false);
      return;
    } else {
      setErr({
        type: "orgId",
        msg: `${ALLOWED_COUNTRIES[country]?.govIdName} not accepted. Please try again.`,
      });
      submitState.orgId.error = true;
      isInvalidField = true;
      setPartnerState(submitState);
      setIsLoading(false);
      return;
    }

    // Goto step form now
    setIsLoading(false);
    handleUpdate();
  };

  useEffect(() => {
    if (Object?.values(partnerState)?.every((obj) => !obj.error)) {
      setErr(null);
    }
  }, [partnerState]);

  return (
    <>
      <div className="flex flex-1 flex-col justify-between gap-4">
        <span className="text-[38px] text-black font-semibold">
          Your Partner Agreement
        </span>

        <span className="w-3/4 text-sm text-gray-600">
          The information you provide here will be used to prepare your
          CyberCert Partner Agreement.
        </span>
      </div>

      <form
        className={`input-form flex flex-1 flex-col justify-end gap-4 ${!err && "tablet:pt-[69px]"}`}
        onSubmit={handleSubmit}
      >
        {!!err?.msg && (
          <Alert
            color="failure"
            icon={BiSolidErrorAlt}
            onDismiss={() => setErr(null)}
          >
            {err.msg}
          </Alert>
        )}

        <div className="subtitle body-sm leading-[21px] text-gray-600">
          To start, please enter your {ALLOWED_COUNTRIES[country]?.govIdName}:
        </div>

        <CountryInput
          disabled={isLoading}
          id="country"
          name="country"
          required
          shadow
          value={country}
          onChange={(event) => setCountry(event?.target?.value)}
          color={"primary"}
        />

        <FlowbiteTextInput
          color={partnerState?.orgId?.error ? "failure" : "gray"}
          disabled={isLoading}
          name="orgId"
          icon={HiOfficeBuilding}
          placeholder={`Enter your ${ALLOWED_COUNTRIES[country]?.govIdName}`}
          required
          type="text"
          value={partnerState?.orgId?.value}
          onKeyDown={(event) =>
            handleEnterKeyFormSubmission(event, () => handleSubmit(event))
          }
          onInput={handleInput}
        />

        <div className="flex gap-1 body-sm text-gray-900">
          <div>Cannot find your organisation or it's been claimed?</div>
          <button
            onClick={openZEWidget}
            className="font-medium text-blue-600 outline-none hover:opacity-70 focus:underline"
            type="button"
          >
            Get Help
          </button>
        </div>

        <SubmitButton
          className="self-end"
          id="submitOrgId"
          variant="small"
          type="submit"
          isLoading={isLoading}
        >
          Continue
        </SubmitButton>
      </form>
    </>
  );
}
